.App {
    text-align: center;
    height: 100%;
}

.App-logo {
    height: 7vmin;
    pointer-events: none;
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.nav-bar {
    background-color: #037171;
    min-height: 6vmin;
    font-size: calc(10px + 2vmin);
    color: white;
}

.footer {
    background-color: #037171;
    min-height: 4vmin;
    font-size: 1.5vmin;
    color: white;
    padding: 0 !important;
}

.footer > p {
    margin-top: 2vmin;
}

#root {
    width: 100%;
    background-color: #efefef;
    min-height: 100%;
}

.App-link {
    color: #61dafb;
}

.recharts-legend-item {
    margin-left: 10px;
    font-size: 12px;
}

.voided {
    background-color: #ffeeed !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


*::-webkit-scrollbar {
    width: .65em;
}

*::-webkit-scrollbar-track {
    background-color: #efefef;
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
        /* outline: 1px solid #037171; */
        border-radius: 10px;
        margin: 0 2px 2px 2px;
}

